import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LISTING_TYPE_CLASS } from '../../util/listingHelpers';
import { ListingLink } from '../../components';
import { EditClassListingDescriptionForm, EditDiningExperienceListingDescriptionForm } from '../../forms';
import config from '../../config';

const { Money } = sdkTypes;

import css from './EditListingDescriptionPanel.module.css';

const EditListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    intl,
    listingType,
    isClassListingFlow,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, publicData } = currentListing.attributes;
  const { materialsProvided, materialsRequired, skillLevel, classDuration, plates } = publicData;
  const initialSkillLevel = skillLevel && {
    value: skillLevel,
    label: intl.formatMessage({ id: `EditListingDescriptionForm.${skillLevel}` })
  };

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.classListingTitle"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id={`EditListingDescriptionPanel.${isClassListingFlow ? 'createClassListing' : 'createDiningExperienceListing'}`} />
  );

  const mapDataToForm = plate => {
    return {
      ...plate,
      price: new Money(plate.price.amount, plate.price.currency),
      style: {
        value: plate.style,
        label: intl.formatMessage({ id: `EditListingDescriptionForm.${plate.style}` })
      },
    }
  }

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      {isClassListingFlow
        ? <EditClassListingDescriptionForm
          className={css.form}
          initialValues={{ title, description, materialsProvided, materialsRequired, skillLevel: initialSkillLevel, classDuration }}
          saveActionMsg={submitButtonText}
          onSubmit={values => {
            const { title, description, materialsProvided, materialsRequired, skillLevel, classDuration } = values;
            const { value: skillLevelValue } = skillLevel;

            const updateValues = {
              title: title.trim(),
              description,
              publicData: {
                listingType: listingType,
                materialsProvided,
                materialsRequired,
                classDuration,
                skillLevel: skillLevelValue,
              },
            };

            onSubmit(updateValues);
          }}
          onChange={onChange}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
        />
        : <EditDiningExperienceListingDescriptionForm
          className={css.form}
          initialValues={{ title }}
          plates={plates && plates.map(plate => mapDataToForm(plate))}
          saveActionMsg={submitButtonText}
          onSubmit={(values) => {
            const { title, plates } = values;

            const { lowestPrice, plates: formattedPlates } = plates.reduce(
              (prev, curr) => {
                const { lowestPrice, plates } = prev;
                const { price, style } = curr;

                const { amount, currency } = price;

                const newPlates = [
                  ...plates,
                  {
                    ...curr,
                    price: {
                      amount,
                      currency,
                    },
                    style: style.value,
                  }
                ]

                return {
                  lowestPrice: lowestPrice > amount ? amount : lowestPrice,
                  plates: [...newPlates],
                }

              },
              //initial values of this reduce()
              {
                lowestPrice: plates[0].price.amount,
                plates: [],
              }
            )

            const updateValues = {
              title: title.trim(),
              price: {
                amount: lowestPrice,
                currency: config.currencyConfig.currency,
              },
              publicData: {
                plates: formattedPlates,
                listingType: listingType,
              }
            };
            onSubmit(updateValues);
          }}
          onChange={onChange}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
        />
      }
    </div>
  );
};

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingDescriptionPanel;
