/**
 * SuffixInput renders an input field that format it's value according to suffix formatting rules
 * onFocus: renders given value in unformatted manner: "9999,99"
 * onBlur: formats the given input: "9 999,99" + suffix
 */
import React, { Component } from 'react';
import { func, oneOfType, number, shape, string, object } from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import Decimal from 'decimal.js';
import { ValidationError } from '../../components';
import { isSafeNumber, ensureDotSeparator } from '../../util/currency';
import { propTypes } from '../../util/types';
import * as log from '../../util/log';

import css from './FieldSuffixInput.module.css';

const allowedInputProps = allProps => {
  // Strip away props that are not passed to input element (or are overwritten)
  const { defaultValue, intl, input, meta, formattedId,formatMessageId, ...inputProps } = allProps;
  return inputProps;
};

class SuffixInputComponent extends Component {
  constructor(props) {
    super(props);
    const {
      defaultValue,
      input: { value },
    } = props;
    try {
      const unformattedValue = value || '';
      const formattedValue = !!unformattedValue ? this.formatValue(unformattedValue) : '';
      this.state = {
        formattedValue,
        unformattedValue,
        value: formattedValue,
      };
    } catch (e) {
      log.error(e, 'missing-format-message-id', { defaultValue, initialValue: value });
      throw e;
    }

    this.onInputChange = this.onInputChange.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onInputFocus = this.onInputFocus.bind(this);
    this.updateValues = this.updateValues.bind(this);
    this.formatValue = this.formatValue.bind(this);
  }

  formatValue(unformattedValue) {
    const isEmptyString = unformattedValue === '';
    const { intl, formatMessageId } = this.props;
    try {
      return isEmptyString
        ? null
        : intl.formatMessage({ id: formatMessageId }, { value: unformattedValue });
    } catch (e) {
      return null;
    }
  }

  onInputChange(event) {
    event.preventDefault();
    event.stopPropagation();
    // Update value strings on state
    const { unformattedValue } = this.updateValues(event);
    // Notify parent component about current price change
    this.props.input.onChange(unformattedValue);
  }

  onInputBlur(event) {
    event.preventDefault();
    event.stopPropagation();
    const {
      input: { onBlur },
    } = this.props;
    this.setState(prevState => {
      if (onBlur) {
        onBlur(prevState.unformattedValue);
      }
      return {
        value: prevState.formattedValue,
      };
    });
  }

  onInputFocus(event) {
    event.preventDefault();
    event.stopPropagation();
    const {
      input: { onFocus },
    } = this.props;
    this.setState(prevState => {
      if (onFocus) {
        onFocus(prevState.unformattedValue);
      }
      return {
        value: prevState.unformattedValue,
      };
    });
  }

  updateValues(event) {
    try {
      const targetValue = event.target.value.trim();
      const isEmptyString = targetValue === '';
      const valueOrZero = isEmptyString ? '0' : targetValue;

      const targetDecimalValue = isEmptyString
        ? null
        : new Decimal(ensureDotSeparator(targetValue));

      const isSafeValue =
        isEmptyString || (targetDecimalValue.isPositive() && isSafeNumber(targetDecimalValue));
      if (!isSafeValue) {
        throw new Error(`Unsafe value: ${targetValue}`);
      }

      const unformattedValue = !isEmptyString ? valueOrZero : '';
      const formattedValue = !isEmptyString ? this.formatValue(unformattedValue) : '';

      this.setState({
        formattedValue,
        value: unformattedValue,
        unformattedValue,
      });

      return { formattedValue, value: unformattedValue, unformattedValue };
    } catch (e) {
      // eslint-disable-next-line no-console
      // console.error(e);

      // If an error occurs while filling input field, use previous values
      // This ensures that string like '12.3r' doesn't end up to a state.
      const { formattedValue, unformattedValue, value } = this.state;
      return { formattedValue, unformattedValue, value };
    }
  }

  render() {
    const { className, defaultValue, placeholder, intl, formattedId } = this.props;
    const placeholderText =
      placeholder || intl.formatNumber({ id: formattedId }, { value: defaultValue });
    return (
      <input
        className={className}
        {...allowedInputProps(this.props)}
        value={this.state.value}
        onChange={this.onInputChange}
        onBlur={this.onInputBlur}
        onFocus={this.onInputFocus}
        type="text"
        placeholder={placeholderText}
      />
    );
  }
}

SuffixInputComponent.defaultProps = {
  className: null,
  defaultValue: null,
  input: null,
  placeholder: null,
};

SuffixInputComponent.propTypes = {
  className: string,
  defaultValue: number,
  intl: intlShape.isRequired,
  input: shape({
    value: oneOfType([string, propTypes.money]),
    onBlur: func,
    onChange: func.isRequired,
    onFocus: func,
  }).isRequired,

  placeholder: string,
};

export const SuffixInput = injectIntl(SuffixInputComponent);

const FieldSuffixInputComponent = props => {
  const { rootClassName, className, id, label, input, meta, ...rest } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { valid, invalid, touched, error } = meta;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = touched && invalid && error;

  const inputClasses = classNames(css.input, {
    [css.inputSuccess]: valid,
    [css.inputError]: hasError,
  });

  const inputProps = { className: inputClasses, id, input, ...rest };
  const classes = classNames(rootClassName, className);
  return (
    <div className={classes}>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <SuffixInput {...inputProps} />
      <ValidationError fieldMeta={meta} />
    </div>
  );
};

FieldSuffixInputComponent.defaultProps = {
  rootClassName: null,
  className: null,
  id: null,
  label: null,
  formattedId: "",
};

FieldSuffixInputComponent.propTypes = {
  rootClassName: string,
  className: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Generated by final-form's Field component
  input: object.isRequired,
  meta: object.isRequired,
  formatMessageId: string.isRequired,
};

const FieldSuffixInput = props => {
  return <Field component={FieldSuffixInputComponent} {...props} />;
};

export default FieldSuffixInput;
