import React, { useState } from "react";
import { Form as FinalForm } from 'react-final-form';
import config from '../../config';
import arrayMutators from 'final-form-arrays';
import classNames from "classnames";
import { maxLength, required, composeValidators, moneySubUnitAmountAtLeast, arrayNotEmpty } from '../../util/validators';
import { PLATE_ALLERGENS, PLATE_STYLES } from "../../util/listingHelpers";
import { formatMoney } from '../../util/currency';
import { FormattedMessage } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { Button, FieldCheckboxGroup, FieldCurrencyInput, FieldCustomSelect, FieldTextInput, Form, Modal } from "../../components";

const { Money } = sdkTypes;

import css from './EditListingDescriptionForm.module.css';

const TITLE_MAX_LENGTH = 80;

const ConfirmRemovePlateModal = (props) => {
  const handleClickConfirmButton = () => {
    props.removePlate();
    props.onClose();
  }

  return (
    <Modal
      {...props}
    >
      <FormattedMessage id="EditListingDescriptionForm.confirmRemove" />
      <Button
        type="button"
        className={css.confirmRemoveButton}
        onClick={() => handleClickConfirmButton()}
      >
        <FormattedMessage id="EditListingDescriptionForm.removePlate" />
      </Button>
    </Modal>
  )
}

const EditPlateForm = (props) => {
  const [confirmRemoveModalOpen, setConfirmRemoveModalOpen] = useState(false);

  return (
    <>
      <FinalForm
        {...props}
        mutators={{ ...arrayMutators }}
        render={formRenderProps => {
          const {
            className,
            disabled,
            handleSubmit,
            intl,
            invalid,
            pristine,
            updated,
            form,
            initialValues,
            values,
            maxPlatesReached,
          } = formRenderProps;

          const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.plateTitle' });
          const titlePlaceholderMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.plateTitlePlaceholder',
          });
          const titleRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.plateTitleRequired',
          });
          const maxLengthMessage = intl.formatMessage(
            { id: 'EditListingDescriptionForm.maxLength' },
            {
              maxLength: TITLE_MAX_LENGTH,
            }
          );

          const styleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.plateStyle' });
          const stylePlaceholderMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.plateStylePlaceholder' });
          const styleRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.plateStyleRequired',
          });

          const ingredientsMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.plateIngredients' });
          const ingredientsPlaceholderMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.plateIngredientsPlaceholder' });
          const ingredientsRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.plateIngredientsRequired',
          });

          const allergensMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.plateAllergens' });
          const allergensRequiredMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.plateAllergensRequired' });

          const priceMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.platePrice' });
          const pricePlaceholderMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.platePricePlaceholder' });

          const maxLength80Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

          const priceRequired = required(
            intl.formatMessage({
              id: 'EditListingPricingForm.priceRequired',
            })
          );
          const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
          const minPriceRequired = moneySubUnitAmountAtLeast(
            intl.formatMessage(
              {
                id: 'EditListingPricingForm.priceTooLow',
              },
              {
                minPrice: formatMoney(intl, minPrice),
              }
            ),
            config.listingMinimumPriceSubUnits
          );
          const priceValidators = config.listingMinimumPriceSubUnits
            ? composeValidators(priceRequired, minPriceRequired)
            : priceRequired;

          const addToPlateMsg = intl.formatMessage({ id: 'EditListingDescriptionForm.addToPlate' });
          const savePlateMsg = intl.formatMessage({ id: 'EditListingDescriptionForm.savePlate' });
          const removePlateMsg = intl.formatMessage({ id: 'EditListingDescriptionForm.removePlate' });
          const resetFormMsg = intl.formatMessage({ id: 'EditListingDescriptionForm.resetForm' });

          const classes = classNames(className);
          const submitReady = updated && pristine;
          const submitDisabled = invalid || maxPlatesReached;

          const handleStopEditForm = () => {
            props.stopEditing();
            form.restart();
          };

          const handleSavePlate = () => {
            props.savePlate(values);
            form.restart();
          };

          const handleRemovePlate = () => {
            props.removePlate(initialValues.id);
            form.restart();
          };

          const formControls = initialValues?.title
            ? (
              <div className={css.editPlateButtons}>
                <Button
                  className={classNames(css.resetFormButton, css.plateButton)}
                  type="button"
                  onClick={() => handleStopEditForm()}
                >
                  {resetFormMsg}
                </Button>

                <span className={css.plateAddToMenuButton}>
                  <Button
                    className={classNames(css.removePlateButton, css.plateButton)}
                    type="button"
                    onClick={() => setConfirmRemoveModalOpen(true)}
                  >
                    {removePlateMsg}
                  </Button>

                  <Button
                    className={css.plateButton}
                    type="button"
                    disabled={submitDisabled}
                    onClick={() => handleSavePlate()}
                  >
                    {savePlateMsg}
                  </Button>
                </span>

              </div>
            )
            : <Button
              className={classNames(css.plateButton, css.plateAddToMenuButton)}
              type="submit"
              disabled={submitDisabled}
              ready={submitReady}
            >
              {addToPlateMsg}
            </Button>

          const plateStyles = PLATE_STYLES.map((style) => {
            return {
              value: style,
              label: intl.formatMessage({ id: `EditListingDescriptionForm.${style}` }),
            }
          })

          return (
            <Form className={classes} onSubmit={(values) => {
              handleSubmit(values);
              form.restart();
            }}>
              <FieldTextInput
                id="title"
                name="title"
                className={css.title}
                type="textarea"
                label={titleMessage}
                placeholder={titlePlaceholderMessage}
                maxLength={TITLE_MAX_LENGTH}
                validate={composeValidators(required(titleRequiredMessage), maxLength80Message)}
              />

              <FieldTextInput
                id="ingredients"
                name="ingredients"
                className={css.title}
                type="textarea"
                label={ingredientsMessage}
                placeholder={ingredientsPlaceholderMessage}
                validate={composeValidators(required(ingredientsRequiredMessage))}
              />

              <FieldCustomSelect
                id="style"
                name="style"
                placeholder={stylePlaceholderMessage}
                options={plateStyles}
                className={css.title}
                intl={intl}
                label={styleMessage}
                validate={composeValidators(required(styleRequiredMessage))}
              />

              <FieldCheckboxGroup
                id="allergens"
                name="allergens"
                className={css.title}
                label={allergensMessage}
                options={PLATE_ALLERGENS.map(allergen => {
                  return {
                    key: allergen,
                    label: intl.formatMessage({ id: `EditListingDescriptionForm.${allergen}` }),
                  }
                })}
                threeColumns
                validate={arrayNotEmpty(allergensRequiredMessage)}
              />

              <FieldCurrencyInput
                id="price"
                name="price"
                className={css.description}
                label={priceMessage}
                placeholder={pricePlaceholderMessage}
                currencyConfig={config.currencyConfig}
                validate={priceValidators}
                isTextarea
              />

              {formControls}

              <ConfirmRemovePlateModal
                id="confirmRemove"
                isOpen={confirmRemoveModalOpen}
                onClose={() => {
                  setConfirmRemoveModalOpen(false);
                }}
                onManageDisableScrolling={() => { }}
                removePlate={() => handleRemovePlate()}
              />
            </Form>
          );
        }}
      />
    </>
  )

}

export default EditPlateForm;