import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ExternalLink, NamedLink } from '../../components';

import css from './TermsOfService.module.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <div className={css.heading}>
        <h1>Homeplate, LLC</h1>
        <h1>Terms of Service</h1>
        <p className={css.lastUpdated}>Last updated: November 8, 2022</p>
      </div>

      <p>This website is owned and operated Homeplate, LLC (“Homeplate” or
        “we/us”). Please read these Terms and Conditions (“Terms”) fully and
        carefully before using www.homeplateco.com (the “Site”) and any of the
        services, features or content provided.</p>
      <p>Please read this page carefully. By using the Site, you agree to be
        bound by all of the Terms set forth below. If you do not accept these
        Terms, please do not use the Site. Homeplate may, in its sole
        discretion, revise these Terms at any time; therefore, you should visit
        this page periodically to review the Terms. By making a purchase from
        Homeplate’s Site, you understand and agree that Homeplate may share
        information about you and your transaction, including for fraud
        prevention, vendor shipping, and credit card authorization.</p>
      <h2>Jurisdiction</h2>
      <p>The Site, including its information, services, and content, is
        intended for use within the United States. Homeplate makes no
        representation that the Site, including its information, services, and
        content, are appropriate or available for use in other locations. Those
        who choose to access the Site from locations other than the United
        States do so on their own initiative and are responsible for compliance
        with local laws as applicable.</p>
      <h2>Terms of Sale</h2>
      <p>Each booking of Homeplate’s services or products, subscriptions, or
        information through the Site (collectively “Products”) is expressly made
        conditional on your assent to these Terms, including without limitation
        the following:</p>
      <ol type="a">
        <li><p><strong>Booking Terms.</strong> All bookings are subject to these
          Terms, and Homeplate’s acceptance of a user order is limited to these
          Terms. Homeplate expressly disclaims and rejects any additional terms.
          These Terms may be modified by a written agreement signed by an
          authorized Homeplate representative.</p></li>
        <li><p><strong>Transfer of information.</strong> By requesting a booking
          on the Site, you may be asked to supply certain information relevant to
          your order including but not limited to, your name, email, phone number
          and credit card information. By requesting a booking, you also consent
          to the transfer of your information as required to complete the
          transaction, as set forth in our Privacy Policy.</p></li>
        <li><p><strong>Order Cancellation and Returns.</strong></p>
          <ol type="i">
            <li><p>Customer Cancellations:</p>
              <ol type="1">
                <li><p>You may not cancel any bookings that are within seventy-two (72)
                  hours of the scheduled service.</p></li>
                <li><p>You may cancel booking requests prior to them being accepted by a
                  chef/provider.</p></li>
              </ol></li>
            <li><p>Homeplate Cancellations:</p>
              <ol type="1">
                <li><p>We reserve the right to refuse or cancel your booking at any time
                  for any reason.</p></li>
                <li><p>In the event we are unable to match a provider/chef with your
                  booking request we will provide you a full refund.</p></li>
                <li><p>In the event the provider/chef declines or cancels your booking
                  request, we will provide you with a full refund.</p></li>
              </ol></li>
          </ol></li>
        <li><p><strong>Booking availability.</strong> We do not guarantee the
          accuracy or completeness of any information on our Site, including
          product images, specifications, availability, and services. We reserve
          the right to change or update information and to correct errors,
          inaccuracies or omissions at any time without prior notice.</p></li>
        <li><p><strong>Taxes.</strong> You are responsible for all applicable
          fees and all federal, state, municipal, and other government taxes on
          the sale and delivery of any Product.</p></li>
        <li><p><strong>Remedies.</strong> In the event of your default under
          these terms and conditions, Homeplate shall have available all rights
          and remedies at law or in equity. You agree to pay Homeplate all costs
          and expenses, including attorneys’ fees, incurred by Homeplate in
          exercising any of its rights and remedies. No failure on the part of
          Homeplate to exercise and no delay in exercising any right or remedy
          shall operate as a waiver thereof. No waiver by Homeplate of any default
          shall constitute a waiver by Homeplate of any additional or subsequent
          default.</p></li>
        <li><p><strong>Purchases</strong>. We will charge your payment method at
          the time of booking, in the event your booking is cancelled in
          accordance with the Order and Cancellation Section, you may be eligible
          for a refund.</p></li>
        <li><p><strong>Promotions.</strong> Any promotions made available
          through the Site may include additional and different terms separate
          from these Terms. In the event promotional terms vary from these Terms,
          the promotional terms will apply.</p></li>
        <li><p><strong>Pricing</strong>. Homeplate reserves the right to revise
          their prices at any time prior to a booking being accepted. The prices
          quoted may be revised by Homeplate subsequent to accepting an order in
          the event of any occurrence affecting delivery caused by government
          action, variation in customs duties, increased shipping charges, higher
          costs, and any other matter beyond the control of Homeplate.</p></li>
      </ol>
      <p><strong>User Account</strong></p>
      <p>All registered users (“Users”) of the Site must be over the age of
        18, and shall create a password and an account (“Account”). You are
        responsible for maintaining the confidentiality of your Account and
        password, including but not limited to the restriction of access to your
        computer, web-enabled device and/or account. You are liable for all
        activities conducted in connection with your Account, and the accuracy
        of all information relating thereto, including contact, technical and
        payment information and your login credentials. You are responsible for
        updating your Account information when it changes, and notifying us if
        there has been any unauthorized use of your Account. You will not allow
        your Account to be shared or used by anyone other than you. We reserve
        the right to refuse service, terminate accounts, and remove or edit
        content in our sole discretion.</p>
      <p><strong>User Responsibilities</strong></p>
      <p>You acknowledge that you are responsible for whatever material you
        submit to the Site, if any, including its legality, reliability,
        appropriateness, originality, or copyright. Your use of the Site shall
        be for lawful purposes only, as outlined below.</p>
      <ol type="a">
        <li><p>You will register an account in your own legal name, even if
          providing care for another person.</p></li>
        <li><p>By placing or downloading material on to the Site, including
          intellectual property, documents, text, images, audio files or other
          audio-visual content to the Site (“User Content”), you represent and
          warrant: (a) you own or otherwise have all necessary rights to the User
          Content you provide and the rights to provide it under this Agreement;
          and (b) the User Content will not cause injury to any person or
          entity.</p></li>
        <li><p>You represent and warrant that you are providing User Content and
          using the Site solely as intended.</p></li>
        <li><p>Without limitation, you may not submit to or transmit through
          this Site any material, or otherwise engage in any conduct that:</p>
          <ol type="i">
            <li><p>Transmit to us material that is copyrighted, unless you are the
              copyright owner or have the permission of the copyright owner;</p></li>
            <li><p>Send material that reveals trade secrets, unless you own them or
              have the permission of the owner;</p></li>
            <li><p>Send material that infringes on any other intellectual property
              rights of others or on the privacy or publicity rights of
              others;</p></li>
            <li><p>Send material that is obscene, defamatory, threatening,
              harassing, abusive, hateful, or embarrassing to another user or any
              other person or entity; Intentionally or unintentionally violate or
              encourage conduct that would violate any local, state, or federal
              law;</p></li>
            <li><p>Send advertisements or solicitations of business;</p></li>
            <li><p>Use the Site in violation of any third-party license or
              agreement;</p></li>
            <li><p>Use, modify, copy, or create derivative works from the Site or
              any marks without the applicable owner’s written permission, including
              without limitation using automated or manual means to access or copy
              content from the Site;</p></li>
            <li><p>Frame, mirror, embed or otherwise incorporate any portion of the
              Site in any other site, service or product without the applicable
              owner’s written permission;</p></li>
            <li><p>Send or store unsolicited, infringing, harassing, obscene,
              threatening, harmful, defamatory, or otherwise unlawful
              content;</p></li>
            <li><p>Facilitate the transmission or use of any malicious code, traps,
              time bombs, malware, viruses, worms, trojan horses and the
              like;</p></li>
            <li><p>Use the Site in any way not expressly authorized by these
              Terms;</p></li>
            <li><p>Send chain letters or pyramid schemes; or</p></li>
            <li><p>Impersonate another person.</p></li>
          </ol></li>
      </ol>
      <p>Homeplate reserves the right to expel you and to prevent you further
        access to the Site for violating these Terms or the law. The violation
        of any of these Terms shall result in the immediate revocation of your
        right to access or use the Site or Site Material (defined below) and
        obligates you to immediately destroy any copies of the Site Material in
        your possession.</p>
      <p>Homeplate has the right but assumes no voluntary duty to monitor and
        edit or remove any comments or product review (“Comments”) posted by
        other users on the Site. You are solely responsible for the Comments you
        post on or through the Site, for their accuracy, and for the
        consequences of submitting and posting those Comments. Also, you should
        be cautious about information provided by others, and you acknowledge
        that the use of any Comments posted on the Site is at your own risk to
        the fullest extent allowed by applicable law. You agree that your
        Comments will not violate any right of any third party, including
        copyright, trademark, privacy or other personal or proprietary
        right.</p>
      <p>We are not responsible for, and we do not endorse, the opinions,
        advice, suggestions or recommendations posted or sent by users in any
        Comments and we specifically disclaim any liability in connection
        therewith to the fullest extent allowed by applicable law.</p>
      <h2>Use of Site Material</h2>
      <p>The contents of the Site, such as text, graphics, images and other
        content (the “Site Material”) are protected by copyright under both
        United States and foreign laws. These Terms do not grant you any license
        whatsoever to the Site Material except as described in this paragraph.
        Unauthorized use of the Site Material violates copyright, trademark, and
        other laws. Except as expressly provided herein, you may not sell or
        modify our Site Material or reproduce, display, distribute, or otherwise
        use the Site Material in any way for any public or commercial purpose.
        Use of the Site Material on any other website or in a networked
        environment is prohibited. Homeplate retains all intellectual property
        rights in the Site Material.</p>
      <h2>Trademarks</h2>
      <p>The names, marks and logos appearing on the Site are, unless
        otherwise noted, trademarks owned by or licensed to Homeplate. Your use
        of these marks, except as provided in these Terms, is prohibited. From
        time to time, Homeplate makes fair use in its Site of trademarks owned
        and used by third parties. Homeplate makes no claim to ownership of
        those marks.</p>
      <p>All other trademarks displayed on the Site are the trademarks of
        their respective owners, and constitute neither an endorsement nor a
        recommendation of those third parties. In addition, such use of
        trademarks or links to the websites of third parties is not intended to
        imply, directly or indirectly, that those third parties endorse or have
        any affiliation with Homeplate or the Site.</p>
      <h2>DISCLAIMER OF WARRANTIES</h2>
      <p>HOMEPLATE DOES NOT WARRANT THAT THIS SITE WILL OPERATE ERROR-FREE OR
        THAT THE SITE AND ITS SERVER ARE FREE OF COMPUTER VIRUSES OR OTHER
        HARMFUL MATERIAL. IF YOUR USE OF THE HOMEPLATE SITE OR THE SITE MATERIAL
        RESULTS IN ANY COSTS OR EXPENSES, INCLUDING, WITHOUT LIMITATION, THE
        NEED FOR SERVICING OR REPLACING EQUIPMENT OR DATA, HOMEPLATE SHALL NOT
        BE RESPONSIBLE FOR THOSE COSTS OR EXPENSES.</p>
      <p>THE SITE, THE SITE MATERIAL, PRODUCT, AND PRODUCT DELIVERY ARE
        PROVIDED ON AN “AS IS” AND “AT YOUR OWN RISK” BASIS WITHOUT ANY
        WARRANTIES OF ANY KIND. HOMEPLATE, TO THE FULLEST EXTENT PERMITTED BY
        LAW, DISCLAIMS ALL WARRANTIES, INCLUDING THE WARRANTY OF
        MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTIES RIGHTS, AND THE
        WARRANTY OF FITNESS FOR PARTICULAR PURPOSE. ALTHOUGH HOMEPLATE STRIVES
        TO PROVIDE THOROUGH AND ACCURATE MATERIALS ON ITS SITE, WE MAKE NO
        WARRANTIES ABOUT THE ACCURACY, RELIABILITY, COMPLETENESS, OR TIMELINESS
        OF THE MATERIAL, SERVICES, SOFTWARE, TEXT, GRAPHICS, AND LINKS.</p>
      <p>HOMEPLATE DOES NOT MAKE ANY WARRANTIES AS TO THE RESULT OR OUTCOME OF
        USING THE SITE, THE PRODUCTS, PRODUCT DELIVERY OR PROPERLY FOLLOWING ANY
        INSTRUCTIONS, RECOMMENDATIONS, DIRECTIONS, OR RECIPES CONTAINED IN THE
        SITE MATERIAL.</p>
      <p>YOU ASSUME ALL RISK FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF
        DATA THAT RESULTS FROM USING, ACCESSING, OR OBTAINING ANY CONTENT FROM
        THE SITES, INCLUDING, WITHOUT LIMITATION, ANY DAMAGES RESULTING FROM
        COMPUTER VIRUSES. HOMEPLATE HAS NO LIABILITY OF ANY KIND FOR ANY LOSS OF
        DATA OR INFORMATION.</p>
      <p>FURTHERMORE, YOU ASSUME ALL RISK FOR ANY DAMAGES, INJURY, LOSSES, OR
        LIABILITIES (COLLECTIVELY “LOSSES”) RELATED TO OR CAUSED FROM THE CHEF’S
        USE OF YOUR KITCHEN, HOME, APARTMENT, SITE, OR OTHER LOCATION IN WHICH
        YOU REQUEST THE PRODUCTS/SERVICES BE DELIVERED. HOMEPLATE HAS NO
        LIABILITY OF ANY KIND FOR ANY LOSS WHATSOEVER THAT YOU MAY INCUR AS A
        RESULT OF THE FOREGOING.</p>
      <p>APPLICABLE LAW(S) MAY NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES
        SO SOME OF OR THE ENTIRE EXCLUSION MAY NOT APPLY TO YOU.</p>
      <p>CERTAIN PROVIDERS OF PRODUCTS MAY SEPARATELY PROVIDE LIMITED
        REPRESENTATIONS AND/OR WARRANTIES REGARDING THEIR PRODUCTS. THIS
        DISCLAIMER DOES NOT APPLY TO SUCH PRODUCT WARRANTIES.</p>
      <h2>LIMITATION OF LIABILITY</h2>
      <p>IN NO EVENT WILL HOMEPLATE, ITS DIRECTORS, OFFICERS, EMPLOYEES,
        AFFILIATES, INVESTORS, AGENTS, MEMBERS OR CONTRACTORS BE LIABLE
        FOR <strong>ANY</strong> DAMAGES RESULTING FROM YOUR USE OF THE SITE, THE
        PRODUCTS PURCHASED FROM THE SITE, OR THE CHEF OR CHEFS’ DELIVERY OF THE
        PRODUCT(S) INCLUDING WITHOUT LIMITATION, SPECIAL, INDIRECT, INCIDENTAL,
        CONSEQUENTIAL, PUNITIVE OR EXEMPLARY DAMAGES (INCLUDING, WITHOUT
        LIMITATION, ATTORNEYS’ FEES, LOSS OF BUSINESS, REVENUE, PROFITS,
        GOODWILL, USE, DATA, ELECTRONICALLY TRANSMITTED ORDERS, OR OTHER
        ECONOMIC ADVANTAGE), EVEN IF HOMEPLATE HAS PREVIOUSLY BEEN ADVISED OF,
        OR REASONABLY COULD HAVE FORESEEN, THE POSSIBILITY OF SUCH DAMAGES,
        HOWEVER THEY ARISE, WHETHER IN BREACH OF CONTRACT OR IN TORT (INCLUDING
        NEGLIGENCE), INCLUDING WITHOUT LIMITATION, DAMAGES DUE TO: (a) THE USE
        OF OR THE INABILITY TO USE OR ACCESS THE SITE, THE PRODUCTS OR SERVICES,
        OR CONTENT; (b) THE COST OF PROCUREMENT OF SUBSTITUTE SERVICES RESULTING
        FROM ANY DATA, INFORMATION, OR SERVICES OBTAINED, OR MESSAGES RECEIVED
        OR TRANSACTIONS ENTERED INTO, THROUGH OR FROM THE SITE; (c) STATEMENTS
        OR CONDUCT OF ANY THIRD PARTY ON THE SITE, INCLUDING WITHOUT LIMITATION,
        UNAUTHORIZED ACCESS TO OR ALTERATION OF TRANSMISSIONS OR DATA, MALICIOUS
        OR CRIMINAL BEHAVIOR, OR FALSE OR FRAUDULENT TRANSACTIONS; (d) CONTENT
        OR INFORMATION YOU MAY DOWNLOAD, USE, MODIFY, RELY ON, OR DISTRIBUTE;
        (e) THE LOSS OF ANY DATA, INFORMATION, OR CONTENT SUBMITTED OR POSTED TO
        THE SITE; (f) THE CHEF’S DELIVERY OF THE PRODUCTS/SERVICE; (g) THE
        CHEF’S USE OF ANY LOCATION (INCLUDING BUT NOT LIMITED TO THE USER’S
        HOUSE, APARTMENT, RENTED LOCATION, OR ANY OTHER REQUESTED DELIVERY
        LOCATION); OR (h) THE CHEF’S USE OF ANY USER’S KITCHEN; UTENSILS,
        MACHINES, TOOLS, POTS PANS ETC.</p>
      <p>IN NO EVENT WILL HOMEPLATE’S LIABILITY OR THE LIABILITY OF ITS
        LICENSORS AND THEIR RESPECTIVE DIRECTORS, OFFICERS, MEMBERS, EMPLOYEES,
        AFFILIATES, INVESTORS, AGENTS, AND CONTRACTORS IN CONNECTION YOUR USE OF
        THE SITE, OR THE PURCHASE OF THE PRODUCTS UNDER ANY THEORY OF RECOVERY,
        EXCEED $1,000.</p>
      <h2>THESE TERMS DEFINE YOUR SOLE AND EXCLUSIVE REMEDY.</h2>
      <p>TO THE EXTENT THAT ANY JURISDICTION DOES NOT ALLOW THE EXCLUSION OR
        LIMITATION OF DIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES, PORTIONS OF
        THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY.</p>
      <p>NEITHER YOU NOR WE WILL PARTICIPATE IN A CLASS ACTION OR CLASS-WIDE
        ARBITRATION FOR ANY CLAIMS COVERED BY THESE TERMS. BY USING THE SITE,
        YOU ARE GIVING UP YOUR RIGHT TO PARTICIPATE AS A CLASS REPRESENTATIVE OR
        CLASS MEMBER IN ANY CLASS CLAIM YOU MAY HAVE AGAINST US INCLUDING ANY
        RIGHT TO CLASS ARBITRATION OR ANY CONSOLIDATION OF INDIVIDUAL
        ARBITRATIONS. YOU ALSO AGREE NOT TO PARTICIPATE IN CLAIMS BROUGHT IN A
        PRIVATE ATTORNEY GENERAL OR REPRESENTATIVE CAPACITY, OR CONSOLIDATED
        CLAIMS INVOLVING ANOTHER PERSON’S ACCOUNT, IF WE ARE A PARTY TO THE
        PROCEEDING.</p>
      <h2>Third-Party Sites</h2>
      <p>Our Site may include links to other sites on the Internet that are
        owned and operated by online merchants and other third parties. You
        acknowledge that Homeplate is not responsible for the availability of,
        or the content located on or through, any third-party site. You should
        contact the site administrator or webmaster for those third-party sites
        if you have any concerns regarding such links or the content located on
        such sites. Your use of those third-party sites is subject to the terms
        of use and privacy policies of each site, and Homeplate is not
        responsible for your use of said third-party sites.</p>
      <h2>Indemnification</h2>
      <p>By using our Site, you agree to indemnify, defend, and hold harmless
        Homeplate, its officers, directors, employees and agents, from and
        against any and all losses, claims, damages, costs and expenses
        (including legal and accounting fees) that Homeplate may become
        obligated to pay arising or resulting from; (i) your use of the Site,
        the Site Material; (ii) your breach of these Terms; (iii) your purchase
        of the Products; (iv) the delivery of the Products(s); (v) the Chef’s
        use of your home, apartment, site, or delivery location of the Products;
        and (vi) any guests, persons or participants who participate in or are
        at the Product delivery location. Homeplate reserves the right to assume
        or participate, at your expense, in the investigation, settlement and
        defense of any such action or claim.</p>
      <h2>Privacy</h2>
      <p>Please see Homeplate’s Privacy Policy. You agree that by accepting
        these Terms, you are consenting to the use and disclosure of your
        personal information and other practices as described in our Privacy
        Policy.</p>
      <h2>Miscellaneous</h2>
      <p>These Terms are governed by the substantive laws of the State of
        Ohio, without respect to its conflict of laws principles. You agree to
        submit to the jurisdiction of the courts situated in the State of Ohio
        with respect to any dispute, disagreement, or cause of action related to
        or involving the Site. If any provision is found to be invalid by any
        court having competent jurisdiction, the invalidity of such provision
        shall not affect the validity of the remaining provisions of these
        Terms, which shall remain in full force and effect. No waiver of any of
        these Terms shall be deemed a further or continuing waiver of such term
        or any other term. Except as expressly provided elsewhere in our Site,
        these Terms constitute the entire agreement between you and Homeplate
        with respect to your use of the Site.</p>
      <p>If any provision of these Terms shall be unlawful, void or
        unenforceable for any reason, the other provisions (and any partially
        enforceable provision) shall not be affected thereby and shall remain
        valid and enforceable to the maximum possible extent. You agree that
        these Terms and any other agreements referenced herein may be assigned
        by us, in our sole discretion, to a third party.</p>
      <p>You acknowledge and agree that Homeplate may preserve and disclose
        the contents of your online communications if required to do so by law,
        or in good faith belief that preservation and/or disclosure is
        reasonably necessary for the following purposes: (a) to comply with
        legal process, such as a court order, search warrant, or subpoena; (b)
        to enforce the terms of these Terms; (c) to render service you request;
        (d) to protect Homeplate’s rights or property; or (e) in circumstances
        that Homeplate deems, in its sole discretion, to pose a threat to the
        safety of you or others.</p>
      <p>Homeplate reserves the right, at any time, to modify, alter, or
        update these Terms without prior notice. Modifications shall become
        effective immediately upon being posted on the Site. Your continued use
        of the Site or transactions made after amendments are posted constitutes
        an acknowledgement and acceptance of these Terms and its modifications.
        Except as expressly stated herein, these Terms may not be amended.</p>
      <p>Homeplate reserves the right to modify or discontinue the Site with
        or without notice. Homeplate shall not be liable to you or any third
        party should Homeplate exercise its right to modify or discontinue the
        Site. you acknowledge and accept that Homeplate does not guarantee
        continuous, uninterrupted, or secure access to the Site and operation of
        the Site may be interfered with or adversely affected by numerous
        factors or circumstances outside of Homeplate’s control.</p>
      <h2>Contact Us</h2>
      <p>If you have any questions regarding these Terms or our Site, you can
        contact us:</p>
      <ul>
        <li><p>By email: <ExternalLink href="mailto:help@homeplateco.com">
          help@homeplateco.com</ExternalLink>
        </p></li>
        <li><p>By visiting this page on our website: <NamedLink name="ContactPage">
          https://homeplateco.com/contact</NamedLink>
        </p></li>
      </ul>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
