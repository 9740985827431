import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ExternalLink, NamedLink } from '../../components';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <div className={css.heading}>
        <h1>Homeplate, LLC</h1>
        <h1>Privacy Policy</h1>
        <p className={css.lastUpdated}>Last updated: November 8, 2022</p>
      </div>

      <p>This Privacy Policy sets out how Homeplate, LLC (“Homeplate” or
        “we/us”) uses and protects the personal information you provide when
        visiting our website www.homeplateco.com (our “Site”). By using the
        Site, you agree to the collection and use of your information in
        accordance with this Privacy Policy. Please read this Privacy Policy
        carefully before using our Site.</p>
      <h2>Collecting and Using your Personal Data</h2>
      <p>While using our Site, we may ask you to provide us with certain
        Personal Data that can be used to contact or identify you. Personal Data
        may include, but is not limited to:</p>
      <ul>
        <li><p>Email address</p></li>
        <li><p>First name and last name</p></li>
        <li><p>Phone number</p></li>
        <li><p>Address, State, Province, ZIP/Postal code, City</p></li>
        <li><p>Bank account information</p></li>
        <li><p>Credit card number and expiration date</p></li>
        <li><p>Usage Data (this is data collected automatically when using the
          Site).</p></li>
      </ul>
      <p>Usage Data may include information such as your device’s Internet
        Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Site that you visit, the time and date of your visit, the
        time spent on those pages, unique device identifiers and other
        diagnostic data. When you access the Site by or through a mobile device,
        we may collect certain information automatically, including, but not
        limited to, the type of mobile device you use, your mobile device unique
        ID, the IP address of your mobile device, your mobile operating system,
        the type of mobile Internet browser you use, unique device identifiers
        and other diagnostic data. We may also collect information that your
        browser sends whenever you visit our Site or when you access the Site by
        or through a mobile device.</p>
      <h2>Tracking Technologies and Cookies</h2>
      <p>We use Cookies and similar tracking technologies to track the
        activity on our Site and store certain information. The technologies we
        use may include:</p>
      <ul>
        <li><p><strong>Cookies or Browser Cookies.</strong> A cookie is a small
          file placed on your device. You can instruct your browser to refuse all
          Cookies or to indicate when a Cookie is being sent. However, if you do
          not accept Cookies, you may not be able to use some parts of our Site.
          Unless you have adjusted your browser setting so that it will refuse
          Cookies, our Site may use Cookies.</p></li>
        <li><p><strong>Flash Cookies.</strong> Certain features of our Site may
          use local stored objects (or Flash cookies) to collect and store
          information about your preferences or your activity on our Site. Flash
          cookies are not managed by the same browser settings as those used for
          Browser cookies. For more information on how you can delete Flash
          cookies, please read <ExternalLink
            href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_"
          >
            "Where can I change the settings for disabling, or deleting local shared objects?"
          </ExternalLink>
        </p></li>
        <li><p><strong>Web Beacons.</strong> Certain sections of our Site and
          our emails may contain small electronic files known as web beacons (also
          referred to as clear gifs, pixel tags, and single-pixel gifs) that
          permit us, for example, to count users who have visited those pages or
          opened an email and for other related application or website statistics
          (for example, recording the popularity of a certain section and
          verifying system and server integrity).</p></li>
      </ul>
      <p>Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
        remain on your personal computer or mobile device when you go offline,
        while Session Cookies are deleted as soon as you close your web
        browser.</p>
      <p>We use both Session and Persistent Cookies for the purposes set out
        below:</p>
      <ul>
        <li><p><strong>Necessary/Essential Cookies</strong>. These Cookies are
          essential to enable you to use some of its features, they help to
          authenticate users and prevent fraudulent use of user accounts. Without
          these Cookies, the Site that you have asked for cannot be provided, and
          we only use these Cookies to provide you with the Site.</p></li>
        <li><p><strong>Functionality Cookies</strong>. These Cookies allow us to
          remember choices you make when you use the Site, such as remembering
          your login details or language preference. The purpose of these Cookies
          is to provide you with a more personal experience and to avoid you
          having to re-enter your preferences every time you use the
          Site.</p></li>
        <li><p><strong>Tracking and Performance Cookies.</strong> These Cookies
          are administered by third parties and are used to track information
          about traffic to our Site and how users use the Site. The information
          gathered via these Cookies may directly or indirectly identify you as an
          individual visitor. This is because the information collected is
          typically linked to a pseudonymous identifier associated with the device
          you use to access the Site. We may also use these Cookies to test new
          pages, features or new functionality of the Site to see how our users
          react to them.</p></li>
      </ul>
      <h2>Use of your Personal Data</h2>
      <p>We may use Personal Data for the following purposes:</p>
      <ul>
        <li><p><strong>To provide and maintain our Site</strong>, including to
          monitor the usage of our Site.</p></li>
        <li><p><strong>To manage your Account:</strong> including your
          registration as a user of the Site. The Personal Data you provide can
          give you access to different functionalities of the Site that are
          available to you as a registered user.</p></li>
        <li><p><strong>Purchase and delivery of products and services:</strong>
          including taking and handling orders, delivering products and services,
          processing payments (third-party processors) and communicating with you
          about orders, products, services, and promotional offers.</p></li>
        <li><p><strong>To contact you:</strong> To contact you by email,
          telephone calls, SMS, or other equivalent forms of electronic
          communication, such as a mobile application's push notifications
          regarding updates or informative communications related to the
          functionalities, products or contracted sites, including the security
          updates, when necessary or reasonable for their implementation.</p></li>
        <li><p><strong>To provide you</strong> <strong>with news, special offers
          and general information</strong>: including about other goods, Site and
          events which we offer that are similar to those that you have already
          purchased or inquired about unless you have opted not to receive such
          information. (Opting out of notifications does not apply to information
          provided to or communications from use regarding a product purchase,
          product services experience, or other transaction.)</p></li>
        <li><p><strong>To manage your requests:</strong> including providing
          responses.</p></li>
        <li><p><strong>To deliver targeted advertising to you</strong>:
          including by using your information to develop and display content and
          advertising (and work with third-party vendors who do so) tailored to
          your interests and/or location and to measure its
          effectiveness.</p></li>
        <li><p><strong>For business transfers:</strong> including to evaluate or
          conduct a merger, divestiture, restructuring, reorganization,
          dissolution, or other sale or transfer of some or all of our assets,
          whether as a going concern or as part of bankruptcy, liquidation, or
          similar proceeding, in which Personal Data held by us about our Site
          users is among the assets transferred.</p></li>
        <li><p><strong>For other purposes</strong>: including for data analysis,
          identifying usage trends, determining the effectiveness of our
          promotional campaigns and to evaluate and improve our Site, products,
          marketing and your experience.</p></li>
      </ul>
      <p>We may share your Personal Data in the following situations:</p>
      <ul>
        <li><p><strong>With Site Providers:</strong> including to monitor and
          analyze the use of our Site, to show advertisements to you to help
          support and maintain our Site, to advertise on third-party websites to
          you after you visited our Site, for payment processing, and to contact
          you.</p></li>
        <li><p><strong>For business transfers:</strong> including in connection
          with, or during negotiations of, any merger, sale of our assets,
          financing, or acquisition of all or a portion of our business to another
          company.</p></li>
        <li><p><strong>With Affiliates:</strong> in which case we will require
          those affiliates to honor this Privacy Policy. Affiliates include our
          parent company and any other subsidiaries, joint venture partners, or
          other companies that we control or that are under common control with
          us.</p></li>
        <li><p><strong>With business partners:</strong> including to offer you
          certain products, content, or promotions.</p></li>
        <li><p><strong>With other users:</strong> including when you share
          Personal Data or otherwise interact in the public areas with other
          users. Such information may be viewed by all users and may be publicly
          distributed outside the Site. If you interact with other users or
          register through a social media account, your contacts on the social
          media site may see your name, profile, pictures and description of your
          activity. Similarly, other users will be able to view descriptions of
          your activity, communicate with you, and view your profile.</p></li>
        <li><p><strong>With your consent</strong>.</p></li>
      </ul>
      <h2>Payments</h2>
      <p>Our Site includes products and services for purchase, which may use
        third-party services for payment processing (e.g. payment processors).
        We will not store or collect your payment card details, which are
        provided directly to our third-party payment processors whose use of
        your Personal Data is governed by their Privacy Policy. These payment
        processors adhere to the standards set by PCI-DSS as managed by the PCI
        Security Standards Council, which is a joint effort of brands like Visa,
        MasterCard, American Express and Discover. PCI-DSS requirements help
        ensure the secure handling of payment information.</p>
      <h2>Communications and Opting Out</h2>
      <p>We may use your Personal Data to contact you with newsletters,
        marketing or promotional materials and other information that may be of
        interest to you. You may opt-out of receiving any, or all, of these
        communications from us by following the unsubscribe link or instructions
        provided in any email we send or by contacting us at
        help@homeplateco.com. This opt out does not apply to information
        provided to or communications from Homeplate regarding a product
        purchase, product service experience, or other transactions.</p>
      <h2>Retention of your Personal Data</h2>
      <p>We will retain your Personal Data only for as long as is necessary
        for the purposes set out in this Privacy Policy. We will retain and use
        your Personal Data to the extent necessary to comply with our legal
        obligations (for example, if we are required to retain your data to
        comply with applicable laws), resolve disputes, and enforce our legal
        agreements and policies.</p>
      <p>Homeplate will also retain Usage Data for internal analysis purposes.
        Usage Data is generally retained for a shorter period of time, except
        when this data is used to strengthen the security or to improve the
        functionality of our Site, or we are legally obligated to retain this
        data for longer time periods.</p>
      <h2>Transfer of your Personal Data</h2>
      <p>Your Personal Data, is processed at our operating offices and in any
        other places where the parties involved in the processing are located.
        It means that this information may be transferred to — and maintained on
        — computers located outside of your state, province, country or other
        governmental jurisdiction where the data protection laws may differ from
        those from your jurisdiction. Your submission of Personal Data to us
        through the Site and your interaction with the Site represent your
        agreement to the transferring and processing of your Personal Data in
        accordance with this Privacy Policy.</p>
      <p>We will take all steps reasonably necessary to ensure that your
        Personal Data is treated securely and in accordance with this Privacy
        Policy.</p>
      <h2>Disclosure of your Personal Data</h2>
      <h3>Business Transactions</h3>
      <p>If we are involved in a merger, acquisition or asset sale, your
        Personal Data may be transferred. We will provide notice before your
        Personal Data becomes subject to a different Privacy Policy.</p>
      <h3>Law enforcement</h3>
      <p>Under certain circumstances, we may be required to disclose your
        Personal Data if required to do so by law or in response to valid
        requests by public authorities (e.g. a court or a government
        agency).</p>
      <h3>Other legal requirements</h3>
      <p>We may disclose your Personal Data based upon a good faith belief
        that such action is necessary to:</p>
      <ul>
        <li><p>Comply with a legal obligation,</p></li>
        <li><p>Protect and defend our rights or property,</p></li>
        <li><p>Prevent or investigate possible wrongdoing in connection with the
          Site,</p></li>
        <li><p>Protect the personal safety of users of the Site or the public,
          and</p></li>
        <li><p>Protect against legal liability.</p></li>
      </ul>
      <h2>Security of your Personal Data</h2>
      <p>The security of your Personal Data is important to us, however no
        method of transmission over the Internet, or method of electronic
        storage is 100% secure. While we strive to use commercially acceptable
        means to protect your Personal Data, we cannot guarantee its absolute
        security.</p>
      <h2>Analytics</h2>
      <p>We may use third-party site providers to monitor and analyze the use
        of our Site. They may include, but are not limited to:</p>
      <ul>
        <li><strong>Google Analytics:</strong> is a web analytics site offered
          by Google that tracks and reports website traffic. Google uses the data
          collected to track and monitor the use of our Site. This data is shared
          with other Google sites. Google may use the collected data to
          contextualize and personalize the ads of its own advertising network.
          You can opt-out of having made your activity on the Site available to
          Google Analytics by installing the Google Analytics opt-out browser
          add-on. The add-on prevents the Google Analytics JavaScript (ga.js,
          analytics.js and dc.js) from sharing information with Google Analytics
          about visits activity. You may opt-out of certain Google Analytics
          features through your mobile device settings, such as your device
          advertising settings or by following the instructions provided by Google
          in its Privacy Policy.</li>
      </ul>
      <h2>Third-Party Use of Cookies and Other Tracking Technologies</h2>
      <p>Some content or applications (including advertisements) on the Site
        may be served by third parties, including advertisers, ad networks and
        servers, content providers, and application providers. These third
        parties may use cookies alone or in conjunction with web beacons or
        other tracking technologies to collect information about you when you
        use our Site. The information they collect may be associated with your
        Personal Data or they may collect information, including Personal Data,
        about your online activities over time and across different websites and
        other online services. They may use this information to provide you with
        interest-based (behavioral) advertising or other targeted content.</p>
      <p>We do not control these third parties’ tracking technologies or how
        they may be used. If you have any questions about an advertisement or
        other targeted content, you should contact the responsible provider
        directly.</p>
      <p>Our Site does not respond to Do Not Track (“DNT”) signals. However,
        some third-party websites do keep track of your browsing activities. If
        you are visiting such websites, you can set your preferences in your web
        browser to inform websites that you do not want to be tracked. You can
        enable or disable DNT by visiting the preferences or settings page of
        your web browser.</p>
      <h2>Links to Other Websites</h2>
      <p>Our Site may contain links to other websites that we do not operate.
        If you click on a third-party link, you will be directed to that
        third-party’s website. We strongly advise you to review the Privacy
        Policy of every website you visit. We have no control over and assume no
        responsibility for the content, privacy policies, or practices of any
        third-party websites.</p>
      <h2>Children’s Privacy</h2>
      <p>Our Site is not directed to anyone under the age of 13. We do not
        knowingly collect Personal Data from anyone under the age of 13. If you
        are a parent or guardian and you are aware that your child has provided
        us with Personal Data, please contact us. If we become aware that we
        have collected Personal Data from anyone under the age of 13 without
        verification of parental consent, we will take steps to remove that
        information from our servers.</p>
      <h2>Changes to this Privacy Policy</h2>
      <p>We may update our Privacy Policy from time to time. We will notify
        you of any changes by posting the new Privacy Policy on this page.</p>
      <p>We will let you know via email and/or a prominent notice on our Site
        prior to the change becoming effective and update the "Last updated"
        date at the top of this Privacy Policy.</p>
      <p>You should review this Privacy Policy periodically for any changes.
        Changes to this Privacy Policy are effective when they are posted on
        this page.</p>
      <h2>Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, you can contact
        us:</p>
      <ul>
        <li><p>By email: <ExternalLink href="mailto:help@homeplateco.com">
          help@homeplateco.com</ExternalLink>
        </p></li>
        <li><p>By visiting this page on our website: <NamedLink name="ContactPage">
          https://homeplateco.com/contact</NamedLink>
        </p></li>
      </ul>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
