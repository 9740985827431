import React from 'react';
import { connect } from 'react-redux';
import { NamedRedirect } from '../../components';
import { inboxParamsRules } from '../../routeConfiguration';
import { hasAccessPermission } from '../../util/data';

const InboxNavigator = (props) => {
  const { currentUser } = props;
  if (!currentUser) {
    return null;
  }

  const validParamsRule = inboxParamsRules.find(({ onlyFor }) => hasAccessPermission(currentUser, onlyFor));
  if (validParamsRule) {
    return <NamedRedirect name="InboxPage" params={validParamsRule.params} />;
  }

  return <NamedRedirect name="LandingPage" />;
};

const mapStateToProps = (state) => {
  const { currentUser } = state.user;
  return { currentUser };
};

export default connect(mapStateToProps)(InboxNavigator);