import React from 'react';
import { Field } from 'react-final-form';
import Select, { components } from 'react-select';
import ValidationError from '../ValidationError/ValidationError';
import classNames from 'classnames';
import { arrayOf, bool, func, object, shape, string } from 'prop-types';
import { node } from 'prop-types';

import css from './FieldCustomSelect.module.css';

const { Option } = components;
const IconOption = props => (
  <Option {...props}>
    <div className={css.option}>
      {props.data.icon}
      {props.data.label}
    </div>
  </Option>
);

const FieldCustomSelectComponent = props => {
  const {
    rootClassName,
    className,
    id,
    label,
    input,
    meta,
    options,
    withIcon,
    placeholder,
    ...rest
  } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }
  const { valid, invalid, touched, error } = meta;
  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = touched && invalid && error;

  const classes = classNames(css.select, css.root || rootClassName, {
    [css.selectSuccess]: valid,
    [css.selectError]: hasError,
  });
  //custom styles of components inside Select
  const customStyles = {
    control: () => { }, //clear default styles
    valueContainer: styles => ({
      ...styles,
      padding: '0',
    }), //apply custom styles in default style of components
    indicatorsContainer: styles => ({
      ...styles,
      marginLeft: 'auto',
      color: 'red',
    }),
    placeholder: defaultStyles => {
      return {
        ...defaultStyles,
        color: 'var(--matterColorAnti)',
      };
    },
  };
  const selectProps = { className: classes, id, ...input, ...meta, ...rest };

  return options ? (
    <div className={className}>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <Select
        {...selectProps}
        options={options}
        styles={customStyles}
        components={{
          IndicatorSeparator: () => null,
          IndicatorsContainer: () => null,
          Option: withIcon ? IconOption : Option,
        }}
        placeholder={placeholder}
      />
      <ValidationError fieldMeta={meta} />
    </div>
  ) : null;
};

const FieldCustomSelect = props => {
  return <Field component={FieldCustomSelectComponent} {...props} />;
};

FieldCustomSelectComponent.defaultProps = {
  rootClassName: null,
  className: null,
  label: null,
  options: null,
};

FieldCustomSelectComponent.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: string,
  meta: object.isRequired,
  input: shape({
    onChange: func.isRequired,
  }).isRequired,
  isMultiple: bool,
  searchable: bool,
  options: arrayOf(
    shape({
      value: string.isRequired,
      label: node.isRequired,
    })
  ),
};

export default FieldCustomSelect;
