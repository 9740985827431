import React from 'react';
import classNames from 'classnames';
import { string } from 'prop-types';

function IconMinus(props) {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || className);
  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <path d="M6 13a1 1 0 1 1 0-2h12a1 1 0 1 1 0 2H6Z" />
    </svg>
  );
}

IconMinus.defaultsProps = {
  className: null,
  rootClassName: null,
};

IconMinus.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconMinus;
