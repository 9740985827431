import React from "react";
import classNames from "classnames";

import css from './PlateControl.module.css';

const EditIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
    >
      <path d="m16 2.012 3 3L16.713 7.3l-3-3zM4 14v3h3l8.299-8.287-3-3zm0 6h16v2H4z"></path>
    </svg>
  )
}


const Plate = (props) => {
  const { plate, isActive } = props;

  const classes = classNames(css.plate, isActive && css.active);

  return (
    <div className={classes}>
      <>
        {plate.title}
      </>
      <button
        type='button'
        className={css.editPlateButton}
        onClick={() => props.selectPlate(plate)}
      >
        <EditIcon />
      </button>
    </div>
  )
}

export default Plate;