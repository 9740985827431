import classNames from 'classnames';
import { number } from 'prop-types';
import React from 'react';
import IconTree from './IconTree';

import css from "./SkillLevel.module.css";

function SkillLevel(props) {
  const { className, rootClassName, activeLevel, iconSize } = props;
  const classes = classNames(rootClassName || css.root, className);
  const iconAmount = new Array(parseInt(3)).fill(0);

  return <div className={classes}>
    {iconAmount.map((_, index) => {
      return <IconTree position={index + 1} key={`icon-${index}`} isDisable={activeLevel < (index + 1)} iconSize={iconSize} />
    })}
  </div>;
}

SkillLevel.propTypes = {
  activeLevel: number.isRequired,
}

export default SkillLevel;