import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_NUMBER_OF_STUDENTS, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';

const LineItemNumberOfStudentsMaybe = props => {
  const { transaction, intl } = props;

  const numberOfStudentsLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_NUMBER_OF_STUDENTS && !item.reversal
  );

  // If commission is passed it will be shown as a fee already reduces from the total price
  let commissionItem = null;

  if (numberOfStudentsLineItem) {
    const formattedUnitPrice = formatMoney(intl, numberOfStudentsLineItem.unitPrice);
    const formattedLinePrice = formatMoney(intl, numberOfStudentsLineItem.lineTotal);

    commissionItem = (
      <>
        <div className={css.lineItem}>
          <span className={css.itemLabel}>
            <FormattedMessage id="BookingBreakdown.pricePerStudent" />
          </span>
          <span className={css.itemValue}>{formattedUnitPrice}</span>
        </div>
        <div className={css.lineItem}>
          <span className={css.itemLabel}>
            <FormattedMessage
              id="BookingBreakdown.numberOfStudents"
              values={{ count: numberOfStudentsLineItem.quantity.toString() }}
            />
          </span>
          <span className={css.itemValue}>{formattedLinePrice}</span>
        </div>
      </>
    );
  }

  return commissionItem;
};

LineItemNumberOfStudentsMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isCustomer: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemNumberOfStudentsMaybe;
