import React, { Component } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { timestampToDate } from '../../util/dates';
import { propTypes } from '../../util/types';
import config from '../../config';
import { CartItemList, Form, IconSpinner, PrimaryButton } from '../../components';
import EstimatedBreakdownMaybe from '../BookingTimeForm/EstimatedBreakdownMaybe';
import FieldDiningExpDateAndTimeInput from '../BookingTimeForm/FieldDiningExpDateAndTimeInput';
import { isTwoArraysEqual } from '../../util/data';

import css from './BookingDiningExpForm.module.css';

const DINING_LENGTH = 1;
const ARRIVE_OFFSET_MIN = 1;
const ARRIVE_OFFSET_MAX = 3;

export class BookingDiningExpFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDiningCartChange: false,
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleCalculateBreakdown = this.handleCalculateBreakdown.bind(this);
    this.handleShowBreakdown = this.handleShowBreakdown.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!isTwoArraysEqual(prevProps.diningCart, this.props.diningCart)) {
      this.setState({
        isDiningCartChange: true,
      });
    }
  }

  handleFormSubmit(e) {
    this.props.onSubmit(e);
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleCalculateBreakdown(event, formValues) {
    event.preventDefault();

    const { bookingStartTime, bookingEndTime } = formValues;
    const startDate = bookingStartTime ? timestampToDate(bookingStartTime) : null;
    const endDate = bookingEndTime ? timestampToDate(bookingEndTime) : null;

    const { listingId, isOwnListing, diningCart } = this.props;

    // We expect values bookingStartTime and bookingEndTime to be strings
    // which is the default case when the value has been selected through the form
    const isSameTime = bookingStartTime === bookingEndTime;

    if (
      bookingStartTime &&
      bookingEndTime &&
      diningCart.length > 0 &&
      !isSameTime &&
      !this.props.fetchLineItemsInProgress
    ) {
      this.props.onFetchTransactionLineItems({
        bookingData: { startDate, endDate, diningCart },
        listingId,
        isOwnListing,
      });
      this.setState({ isDiningCartChange: false });
    }
  }

  handleShowBreakdown(formValues) {
    return e => this.handleCalculateBreakdown(e, formValues);
  }

  render() {
    const { rootClassName, className, price: unitPrice, isCustomer, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            pristine,
            handleSubmit,
            intl,
            isOwnListing,
            listingId,
            submitButtonWrapperClassName,
            unitType,
            values,
            monthlyTimeSlots,
            onFetchTimeSlots,
            timeZone,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            diningCart,
            updateDiningCart,
          } = fieldRenderProps;

          const startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
          const endTime = values && values.bookingEndTime ? values.bookingEndTime : null;
          const isDiningCartChange = this.state.isDiningCartChange;

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingEndTitle',
          });
          const diningCartTitle = intl.formatMessage({ id: 'BookingTimeForm.diningCartTitle' });
          const startDate = startTime ? timestampToDate(startTime) : null;
          const endDate = endTime ? timestampToDate(endTime) : null;
          const isPlateNotChoose = !!startDate && !!endDate && !diningCart?.length > 0;

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData =
            startDate && endDate
              ? {
                  unitType,
                  startDate,
                  endDate,
                  timeZone,
                }
              : null;

          const showEstimatedBreakdown =
            diningCart.length > 0 &&
            !isDiningCartChange &&
            bookingData &&
            lineItems &&
            !fetchLineItemsInProgress &&
            !fetchLineItemsError;

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
              </h3>
              <EstimatedBreakdownMaybe bookingData={bookingData} lineItems={lineItems} />
            </div>
          ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingTimeForm.fetchLineItemsError" />
            </span>
          ) : null;
          const plateNotChooseMessage = isPlateNotChoose ? (
            <p className={css.notice}>
              <FormattedMessage id="BookingDiningExpForm.plateNotChoose" />
            </p>
          ) : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const startDateInputProps = {
            label: bookingStartLabel,
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            label: bookingEndLabel,
            placeholderText: endDatePlaceholder,
          };

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };

          return (
            <Form onSubmit={handleSubmit} className={classes} enforcePagePreloadFor="CheckoutPage">
              {monthlyTimeSlots && timeZone ? (
                <>
                  <FieldDiningExpDateAndTimeInput
                    {...dateInputProps}
                    className={css.bookingDates}
                    listingId={listingId}
                    bookingStartLabel={bookingStartLabel}
                    onFetchTimeSlots={onFetchTimeSlots}
                    monthlyTimeSlots={monthlyTimeSlots}
                    values={values}
                    intl={intl}
                    form={form}
                    pristine={pristine}
                    timeZone={timeZone}
                    diningLength={DINING_LENGTH}
                    arriveOffsetMin={ARRIVE_OFFSET_MIN}
                    arriveOffsetMax={ARRIVE_OFFSET_MAX}
                  />
                  <CartItemList
                    cartTitle={diningCartTitle}
                    cart={diningCart}
                    updateCart={updateDiningCart}
                  />
                </>
              ) : null}

              {plateNotChooseMessage}
              {bookingInfoMaybe}
              {loadingSpinnerMaybe}
              {bookingInfoErrorMaybe}

              <p className={css.smallPrint}>
                <FormattedMessage
                  id={
                    isOwnListing
                      ? 'BookingTimeForm.ownListing'
                      : 'BookingTimeForm.youWontBeChargedInfo'
                  }
                />
              </p>
              <div className={submitButtonClasses}>
                {showEstimatedBreakdown ? (
                  <PrimaryButton type="submit" disabled={!!fetchLineItemsError || !isCustomer}>
                    <FormattedMessage id="BookingTimeForm.requestToBook" />
                  </PrimaryButton>
                ) : (
                  <PrimaryButton onClick={this.handleShowBreakdown(values)}>
                    <FormattedMessage id="BookingTimeForm.showBreakdown" />
                  </PrimaryButton>
                )}
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

BookingDiningExpFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingDiningExpFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  updateDiningCart: func.isRequired,
  diningCart: array.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingDiningExpForm = compose(injectIntl)(BookingDiningExpFormComponent);
BookingDiningExpForm.displayName = 'BookingDiningExpForm';

export default BookingDiningExpForm;
