import React from 'react';
import { bool, string, number } from 'prop-types';
import classNames from 'classnames';
import css from './SkillLevel.module.css';

function IconTree(props) {
  const { isDisable, iconSize, position } = props;

  const classes = classNames(isDisable ? css.disable : css.active);

  switch (position) {
    case 1: {
      return (
        <svg
          width={iconSize}
          height={iconSize}
          className={classes}
          viewBox="0 0 1010 1010"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Layer_2_1_">
            <path
              className={css.st0}
              d="M503.5,659.8c-3.2-48-17.1-75.4-13.6-120.1c2.5-31.6,6.5-76.5,52.7-96.3c7.9-3.4,17.8-5.2,26.5-8
		c19.2-6.1,32.3-16.4,40.2-27.4c7.9-11,15.2-45.6,13.8-57.5c-0.9-8.2-8.6-31-25.2-32.3c-28.5-2.1-51.1,33.6-58.2,46
		c-5,8.7-11.5,25.5-12.1,34c-1,14.9,1.9,29.8,8.5,44.3c5.8,12.6,15.2,25.4,34,33.8c12.7,5.7,29.7,8.9,46.3,8
		c16.6-0.9,32.4-6.3,39.3-14.4c6.9-8,3.6-18.3-8.9-24.2c-9.1-4.2-21.4-5.8-33.2-7.1c-15.3-1.7-33.3-3.6-48.7-2.2"
            />
            <path
              className={css.st0}
              d="M433.4,502.1c-18.7,1-42.3-2.7-60.4-6.5c-8-1.7-16.2-4-21.8-8.9c-7.3-6.4-8.7-15.8-7.5-24.4
		c1-7.7,3.9-15.6,10-21.5c6.1-6,16-9.9,25.5-8.5c10.9,1.5,18.7,9.1,25,16.5c15.6,18.4,27.8,39.3,30.8,61.6
		c3,22.2-4.1,45.9-22.9,62.4c-5.7,5-12.5,9.4-20.5,11.4s-17.3,1.5-24.1-2.5c-8.6-5.1-11.5-14.9-9.4-23.3c2.1-8.5,8.4-15.8,15.3-22.5
		c14.5-13.9,42.8-35.8,64.9-33.1c43.7,5.3,51.2,49.5,51.2,49.5"
            />
            <path className={css.st0} d="M161.2,657.5c220,0.5,440,2.5,660,4.5" />
            <path
              className={css.st0}
              d="M507.5,659.8c14.4,10.9,25.5,31.4,28.3,48c2.8,16.5-7.6,32.5-9,49.2c-0.7,8.4,0.9,17,0,25.4s-5.1,17.4-13.8,21
		c-12.8,5.3-27.4-3.9-35.3-14.3c-14.3-18.9-18-43.3-12.6-65.5C470.5,701.3,489,673.1,507.5,659.8z"
            />
            <path className={css.st1} d="M491.3,715c5.3,0,46.8-2.9,42.8,0.9" />
            <path className={css.st1} d="M494.3,770.6c-9.3-1.7-17.3,1.4-26.6-0.2" />
          </g>
          <path className={css.st0} d="M461.4,738.8c2.7-0.9,10.7-1.4,13.5-0.7" />
          <ellipse className={css.st2} cx="505" cy="511.1" rx="466.6" ry="462.4" />
        </svg>
      );
    }
    case 2: {
      return (
        <svg
          width={iconSize}
          height={iconSize}
          className={classes}
          viewBox="0 0 1010 1010"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Layer_2_1_">
            <path
              className={css.st0}
              d="M497.6,614.5c9.3-77.4,18.1-157.5-24.2-230.5c-16.3-28.2-39.8-54.5-58.2-82.3s-17-57.9-8.7-86.6"
            />
            <path className={css.st0} d="M442.5,339.2c4.5-12.7,9.1-25.5,13.7-38.2" />
            <path
              className={css.st0}
              d="M342.5,267.5c36.3,8.6,64.3,27.6,82.9,48.8c18.5,21.2,35.1,40.2,45.2,63.5"
            />
            <path
              className={css.st0}
              d="M494.6,433.7c14.2-12.9,31.5-29.6,38.6-44.6c5.1-10.7,9.1-31.2,5.6-42.1c-5.5-16.8-13.5-33.2-19.6-51.9
		c-12.7-39.2-45.5-59.7-66.1-78.9c-18.8-17.5-37.6-39.7-67.5-53.1c-4.1-1.8-8.9-3.6-13.9-3.1c-4.6,0.5-8,3-10.8,5.3
		c-44.6,36.6-43.6,85.2-43.6,130.6c0,13.7,2.5,22.1,5.6,35c3.1,12.5,11.8,33.9,19.8,44.6c14,18.7,38.1,30.7,66.6,40.2
		c28.5,9.6,33.7,11.3,66.7,16.2c18.6,2.6,19.2-0.5,20.6,7.9"
            />
            <path
              className={css.st0}
              d="M586.4,402.5c20.4,4.9,84.7,2.5,100.6-7.4c10.7-6.6,49.8-40.3,52.9-51.6c8.8-31.4,7-79.7-8.9-87.5
		c-8.5-4.2-57,12.8-80.2,21.3c-8,2.9-15.2,7.8-20.9,14.1c0,0-80.2,60.9-44.5,106.5c18.4,23.5-66.6,104.7-80.2,138.8"
            />
            <path
              className={css.st0}
              d="M500.9,617.3c-29.3,2.6-56.2,20.3-67.3,44.5c-11.1,24.1-5.9,53.6,12.9,73.6c10,10.6,23.8,19.2,28.4,32.4
		c3.9,11.1,0.3,23.1-0.7,34.7c-3,34.7,17.3,67.3,36.9,97.5C499.8,878,492,854.7,488,830.9c-4.4-26.1-2.6-56.3,19.8-73.7
		c11.8-9.1,27.9-13.4,38.7-23.5c11.3-10.6,15-26.1,14-40.6C557.9,657,526.3,624,500.9,617.3z"
            />
            <path className={css.st0} d="M470.7,670.7c-13.3-0.7-26.6,0-39.7,2.1" />
            <path className={css.st0} d="M558.3,686.7c-12.4-4.8-21-5.4-37.9-5.4" />
            <path className={css.st0} d="M550.9,728.8c-18.4,0.2-36.8,0.5-55.2,0.7" />
            <path className={css.st0} d="M811.8,648.5c-89.4,0-178.9-0.9-270.9-0.9" />
            <path className={css.st0} d="M442.5,648.5c-84.5,0-164.7,0-250.7,0" />
          </g>
          <path className={css.st0} d="M589.9,400.1c23.3-61.7,90-49,96.9-80.7" />
          <path className={css.st0} d="M653.8,324.7c-0.3,4.4-13.8,23.2-15.5,26.4" />
          <path
            className={css.st0}
            d="M427.9,674.4c1.8-1.3,5.9-2.3,7.9-2.3c2.9,0,4.9,0.3,7.8-0.3"
          />
          <circle className={css.st2} cx="505" cy="500.7" r="462.4" />
        </svg>
      );
    }
    case 3: {
      return (
        <svg
          width={iconSize}
          height={iconSize}
          className={classes}
          viewBox="0 0 1010 1010"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Layer_2_1_">
            <path
              className={css.st0}
              d="M602.8,549.2c32.2,1.3,76.5-21.2,126.4-107.6c17.6-30.4-1.3-56.6-17.4-82.3c-55.4-88.7-27.1-99.1-78.5-86
		c-12.4,3.1-22.2,9.2-31,15.4c-60.8,42.6-128,165.2-90.6,220.1C525.5,529,567.2,547.8,602.8,549.2"
            />
            <path
              className={css.st0}
              d="M490.2,624c0,0-32.2-91.6-87.2-122.2s-115.2-59.7-149-103.7"
            />
            <path className={css.st0} d="M318.2,455c3.1-22.3-25.4-52.7-25.4-52.7" />
            <path className={css.st0} d="M374,485.9c-11.7-13.5-7.2-45.9-17.2-54.5" />
            <path className={css.st0} d="M357.7,476.9c-18.9-11.2-60.6-4.6-83.9-18.4" />
            <path
              className={css.st0}
              d="M448.9,534.4c8.9-23.9,2.7-50.5-16.5-70.9c-15-15.9-36.7-27.5-56.7-40c-31.6-19.7-60.1-42.2-84.4-66.8
		c-5.5-5.5-11.2-11.5-19.7-14.3c-14.7-4.9-32.8,1.5-41.5,11.4c-8.7,9.9-10,22.3-9.8,34.1c0.2,18,3.3,36.1,10.9,53.3
		c7.2,16.3,18.4,31.5,29.6,46.6c15.5,21,38.8,32.9,64.4,51.9c16.6,12.3,42.9,23.7,62.8,20.4c62.6-10.3,61.7-29,61.7-29"
            />
            <path
              className={css.st0}
              d="M478.4,465.1c1.2,12.9-7.3-39.5-69.4-78.7c-22.1-14-55.3-21.7-74.7-41.2c-21-21-23.5-118.5-22.1-142.2
		c1.1-18.9,2.5-38.6,15.1-55c12.7-16.5,40.4-28.4,64.7-22c18.8,5,29.2,51.6,103.5,106.4c18.9,14,53.8,58.6,33.4,83.7
		C474.4,383.2,478.4,465.1,478.4,465.1z"
            />
            <path
              className={css.st0}
              d="M485.6,405c-4.2-20.9-9.1-35.6-19.5-56.4c-12.5-25-42.5-61.9-63.2-83.2c-20.6-21.3-37.8-46.5-34-72.5"
            />
            <path
              className={css.st0}
              d="M407.6,191.9c-5.2,25.5-7.3,53.1,1.4,79.4c-19.9-0.8-37.9-3.2-57.7-4"
            />
            <path
              className={css.st0}
              d="M436.6,247.5c8.9,27.8,14.1,56.3,19.2,86.6c-3.4-4.4-7.5-16.2-14.4-16.9s-13.8-0.3-20.7-0.7
		c-18.5-1-36.2-8.6-46.4-20"
            />
            <path
              className={css.st0}
              d="M583.5,547.9c28.9-18.4,38.8-45.4,37.8-71c-1-25.6-3.9-43.8-10.8-69c-6.9-25.2-9.7-51.7,4.3-75.8"
            />
            <path className={css.st0} d="M612.6,416.1c15.2-18.3,23.7-38.7,24.6-59.3" />
            <path className={css.st0} d="M616.8,435c-29.6-14-47.5-37.1-46.5-60.1" />
            <path className={css.st0} d="M621.1,469.6c22.6-20.5,45.8-39.8,61.4-60.6" />
            <path
              className={css.st0}
              d="M620,508.1c-13.2-5.3-29.3-16.4-34.1-26.1c-4.3-8.7-7.6-12-4.6-21.5"
            />
            <path className={css.st0} d="M583.7,546.1c-49.2,49.2-54.5,78.9-56.7,80.4" />
            <path
              className={css.st0}
              d="M562.2,642.9c-18.2-9.1-1.8-1.4-22.6-11.8c-22.4-11.2-51.9-10.6-74.1,0.9c-22.2,11.4-38.9,33.7-42.5,58.5
		c-3.6,24.8,6.6,51.4,26.5,66.5c10.2,7.8,22.6,12.4,35.1,15.1c20.4,4.3,42.2,3.4,61.5-4.7c19.3-8,35.7-23.5,43.1-43.1
		c5.8-15.5,5.7-33.1-0.2-48.5c-5.9-15.4-13.8-26.2-28.5-33.8"
            />
            <path className={css.st0} d="M590.1,693.2c74.4,0,198.1-0.7,246-0.7" />
            <path className={css.st0} d="M423.8,693.1c-75.3,0.1-150.7,0.2-226,0.3" />
            <path
              className={css.st0}
              d="M493.7,775.4c10.9,20.8,7,45.9,3.1,69.1c-3.8,23.2-7.3,51.4,4.8,71.5"
            />
            <path
              className={css.st0}
              d="M510.4,852.1c-7.5-18.9-9.3-41.7-14.5-61c-1.4-5.1,1.1-10.5,5.7-12.5c7.5-3.3,21.9-2.4,33.6-7.5"
            />
            <path
              className={css.st0}
              d="M493.5,784.6c-2.3-4.1-4.7-8.1-6.5-10.8c7.9,15.9,12.6,33.5,13.5,51.3"
            />
            <path className={css.st1} d="M450.2,690.6c-8.8,0.9-17.6,1.2-26.4,0.9" />
            <path className={css.st1} d="M483.5,644c-8.9-0.7-34.4,4.9-41.3,5" />
            <path className={css.st1} d="M562.1,659.9c-9.6-1.8-49.5-1.5-66.5,0.1" />
            <path className={css.st1} d="M586.2,674.5c-19-2.3-50.1-3.4-59.2-0.1" />
            <path className={css.st1} d="M541.2,697.7c-11.4,0-22.7,0.1-34.1,0.1" />
            <path className={css.st1} d="M588.3,721.1c-23.5-0.8-47-1.6-70.4-2.4" />
            <path className={css.st1} d="M462.9,702c-18.4,0-20.9-0.1-41.3,1.4" />
            <path className={css.st1} d="M473.9,730.1c-15.7,1.2-31.4,1.6-47.2,1" />
            <path className={css.st1} d="M492.3,743.3c-27.2,2.9-32.6,0.1-54.7,3.2" />
          </g>
          <circle className={css.st2} cx="505" cy="500.7" r="462.4" />
        </svg>
      );
    }
    default: {
      return (
        <svg
          width={iconSize}
          height={iconSize}
          className={classes}
          viewBox="0 0 1010 1010"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Layer_2_1_">
            <path
              className={css.st0}
              d="M503.5,659.8c-3.2-48-17.1-75.4-13.6-120.1c2.5-31.6,6.5-76.5,52.7-96.3c7.9-3.4,17.8-5.2,26.5-8
		c19.2-6.1,32.3-16.4,40.2-27.4c7.9-11,15.2-45.6,13.8-57.5c-0.9-8.2-8.6-31-25.2-32.3c-28.5-2.1-51.1,33.6-58.2,46
		c-5,8.7-11.5,25.5-12.1,34c-1,14.9,1.9,29.8,8.5,44.3c5.8,12.6,15.2,25.4,34,33.8c12.7,5.7,29.7,8.9,46.3,8
		c16.6-0.9,32.4-6.3,39.3-14.4c6.9-8,3.6-18.3-8.9-24.2c-9.1-4.2-21.4-5.8-33.2-7.1c-15.3-1.7-33.3-3.6-48.7-2.2"
            />
            <path
              className={css.st0}
              d="M433.4,502.1c-18.7,1-42.3-2.7-60.4-6.5c-8-1.7-16.2-4-21.8-8.9c-7.3-6.4-8.7-15.8-7.5-24.4
		c1-7.7,3.9-15.6,10-21.5c6.1-6,16-9.9,25.5-8.5c10.9,1.5,18.7,9.1,25,16.5c15.6,18.4,27.8,39.3,30.8,61.6
		c3,22.2-4.1,45.9-22.9,62.4c-5.7,5-12.5,9.4-20.5,11.4s-17.3,1.5-24.1-2.5c-8.6-5.1-11.5-14.9-9.4-23.3c2.1-8.5,8.4-15.8,15.3-22.5
		c14.5-13.9,42.8-35.8,64.9-33.1c43.7,5.3,51.2,49.5,51.2,49.5"
            />
            <path className={css.st0} d="M161.2,657.5c220,0.5,440,2.5,660,4.5" />
            <path
              className={css.st0}
              d="M507.5,659.8c14.4,10.9,25.5,31.4,28.3,48c2.8,16.5-7.6,32.5-9,49.2c-0.7,8.4,0.9,17,0,25.4s-5.1,17.4-13.8,21
		c-12.8,5.3-27.4-3.9-35.3-14.3c-14.3-18.9-18-43.3-12.6-65.5C470.5,701.3,489,673.1,507.5,659.8z"
            />
            <path className={css.st1} d="M491.3,715c5.3,0,46.8-2.9,42.8,0.9" />
            <path className={css.st1} d="M494.3,770.6c-9.3-1.7-17.3,1.4-26.6-0.2" />
          </g>
          <path className={css.st0} d="M461.4,738.8c2.7-0.9,10.7-1.4,13.5-0.7" />
          <ellipse className={css.st2} cx="505" cy="511.1" rx="466.6" ry="462.4" />
        </svg>
      );
    }
  }
}

IconTree.defaultProps = {
  className: null,
  rootClassName: null,
  isDisable: false,
  iconSize: 36,
};

IconTree.propTypes = {
  className: string,
  rootClassName: string,
  isDisable: bool,
  iconSize: number,
  position: number.isRequired,
};

export default IconTree;
