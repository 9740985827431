import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import config from '../../config';

import IconLogo from './IconLogo';

import desktopLogo from './homeplate-logo.png';

import css from './Logo.module.css';

const Logo = props => {
  const { className, format, config, ...rest } = props;
  const mobileClasses = classNames(css.logoMobile, className);
  const desktopClasses = classNames(css.logoDesktop, className);
  const isDesktop = format === 'desktop';
  const logo = isDesktop
    ? <img className={desktopClasses} src={desktopLogo} alt={config.siteTitle} {...rest} />
    : <IconLogo
      className={mobileClasses}
      format={format}
      {...rest}
    />

  // If you want to use image instead of svg as a logo you can use the following code.
  // Also, remember to import the image as LogoImage here.
  // <img className={className} src={LogoImage} alt={config.siteTitle} {...rest} />

  return logo;
};

const { oneOf, string } = PropTypes;

Logo.defaultProps = {
  className: null,
  format: 'desktop',
  config: config,
};

Logo.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile']),
};

export default Logo;
