import React from 'react';
import { array, func } from 'prop-types';
import classNames from 'classnames';
import CartItem from './CartItem';

import css from './CartItemList.module.css';

function CartItemList(props) {
  const { cartTitle, cart, className, rootClassName, updateCart } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <h3>{cartTitle}</h3>
      {cart.map(item =>
        item.quantity ? <CartItem key={item.id} updateCart={updateCart} {...item} /> : null
      )}
    </div>
  );
}

CartItemList.propTypes = {
  cart: array.isRequired,
  updateCart: func.isRequired,
};

export default CartItemList;
