import React from "react";
import classNames from 'classnames';
import Plate from "./Plate";

import css from './PlateControl.module.css';

const PlateControl = (props) => {
  const { className, plates, activeKey } = props;

  return (
    <div className={classNames(className, css.root)}>
      {plates.map((plate) => (
        <Plate
          key={plate.id}
          plate={plate}
          selectPlate={props.selectPlate}
          isActive={activeKey === plate.id}
        />
      ))}
    </div>
  )
}

export default PlateControl;