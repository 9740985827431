import React from 'react';
import { InlineTextButton } from '../Button/Button';
import { number, string } from 'prop-types';
import IconClose from '../IconClose/IconClose';

import css from './CartItemList.module.css';

function CartItem(props) {
  const { title, quantity, id, updateCart } = props;
  return (
    <div className={css.cartItem}>
      <p className={css.itemName}>{title}</p> <p className={css.quantity}> x {quantity} </p>
      <InlineTextButton
        className={css.removeButton}
        onClick={() => updateCart(id, title, -quantity)}
      >
        <IconClose />
      </InlineTextButton>
    </div>
  );
}

CartItem.propTypes = {
  title: string.isRequired,
  quantity: number.isRequired,
  id: string.isRequired,
};

export default CartItem;
