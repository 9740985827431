// LISTING TYPE
export const LISTING_TYPE_DINING_EXPERIENCE = 'diningExp';
export const LISTING_TYPE_CLASS = 'class';

export const getListingType = listing => {
  return listing?.attributes?.publicData?.listingType;
};

export const isClassListing = listing => {
  return getListingType(listing) === LISTING_TYPE_CLASS;
};

export const isDiningExpListing = listing => {
  return getListingType(listing) === LISTING_TYPE_DINING_EXPERIENCE;
};

// AUTHOR
export const getAuthor = listing => {
  return listing?.author?.attributes.profile;
};

//SKILL LEVELS
export const SKILL_LEVEL_BEGINNER = 'beginner';
export const SKILL_LEVEL_INTERMEDIATE = 'intermediate';
export const SKILL_LEVEL_ADVANCED = 'advanced';
export const SKILL_LEVELS = {
  [SKILL_LEVEL_BEGINNER]: 1,
  [SKILL_LEVEL_INTERMEDIATE]: 2,
  [SKILL_LEVEL_ADVANCED]: 3,
};

export const getSkillLevelNames = () => Object.keys(SKILL_LEVELS);

//STYLES
export const PLATE_STYLE_STARTER = 'starter';
export const PLATE_STYLE_ENTREE = 'entree';
export const PLATE_STYLE_SIDES = 'sides';
export const PLATE_STYLE_SWEET = 'sweet';
export const PLATE_STYLE_FAMILY = 'familyStyle';
export const PLATE_STYLES = [
  PLATE_STYLE_STARTER,
  PLATE_STYLE_ENTREE,
  PLATE_STYLE_SIDES,
  PLATE_STYLE_SWEET,
  PLATE_STYLE_FAMILY,
];

//ALLERGENS
export const PLATE_ALLERGEN_GLUTEN = 'gluten';
export const PLATE_ALLERGEN_SOY = 'soy';
export const PLATE_ALLERGEN_TREENUTS = 'treenuts';
export const PLATE_ALLERGEN_DAIRY = 'dairy';
export const PLATE_ALLERGEN_ALLIUM = 'allium';
export const PLATE_ALLERGEN_FISH = 'fish';
export const PLATE_ALLERGEN_EGGS = 'eggs';
export const PLATE_ALLERGEN_PEANUTS = 'peanuts';
export const PLATE_ALLERGEN_SHELLFISH = 'shellfish';
export const PLATE_ALLERGENS = [
  PLATE_ALLERGEN_ALLIUM,
  PLATE_ALLERGEN_DAIRY,
  PLATE_ALLERGEN_EGGS,
  PLATE_ALLERGEN_FISH,
  PLATE_ALLERGEN_GLUTEN,
  PLATE_ALLERGEN_PEANUTS,
  PLATE_ALLERGEN_SHELLFISH,
  PLATE_ALLERGEN_SOY,
  PLATE_ALLERGEN_TREENUTS,
];
