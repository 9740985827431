import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { v4 as uuidV4 } from 'uuid';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, Button, FieldTextInput } from '../../components';
import EditPlateForm from './EditPlateForm';
import PlateControl from './PlateControl/PlateControl';

import css from './EditListingDescriptionForm.module.css';

const TITLE_MAX_LENGTH = 80;
const PLATES_MAX_AMOUNT = 20;

const EditDiningExperienceListingDescriptionFormComponent = props => {
  const { plates, intl } = props;

  const [activePlate, setActivePlate] = useState({});
  const [plateForm, setPlateForm] = useState(null)
  const [renderPlates, setRenderPlates] = useState(plates || []);

  const resetFormData = () => {
    setActivePlate({});
  }

  const handleRemovePlate = (id) => {
    resetFormData();
    setRenderPlates(renderPlates.filter(plate => plate.id !== id));
  }

  const handleSavePlate = (updatedPlate) => {
    setRenderPlates((renderPlates) => {
      const updateIndex = renderPlates.findIndex((plate) => plate.id === updatedPlate.id);
      renderPlates[updateIndex] = { ...updatedPlate };
      return renderPlates;
    })
    resetFormData();
  };

  const handleAddPlate = (values) => {
    setRenderPlates([...renderPlates, {
      ...values,
      id: uuidV4(),
    }]);
    resetFormData();
  }

  useEffect(() => {
    setPlateForm(
      <EditPlateForm
        key={activePlate?.id}
        id='EditPlateForm'
        className={css.plateForm}
        initialValues={activePlate}
        removePlate={handleRemovePlate}
        stopEditing={resetFormData}
        savePlate={handleSavePlate}
        onSubmit={handleAddPlate}
        intl={intl}
        maxPlatesReached={renderPlates.length === PLATES_MAX_AMOUNT}
      />
    )
  }, [activePlate, renderPlates]);

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          form,
        } = formRenderProps;

        const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.titleDiningExperienceListing' });
        const titlePlaceholderMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.titleDiningExperienceListingPlaceholder',
        });
        const titleRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.titleDiningExperienceListingRequired',
        });
        const maxLengthMessage = intl.formatMessage(
          { id: 'EditListingDescriptionForm.maxLength' },
          {
            maxLength: TITLE_MAX_LENGTH,
          }
        );

        const maxLength80Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

        const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
          </p>
        ) : null;

        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
          </p>
        ) : null;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress || renderPlates.length < 1;

        return (
          <>
            <Form
              className={classes}
              onSubmit={(e) => {
                e.preventDefault();
                form.change('plates', [...renderPlates]);
                handleSubmit();
              }}
            >
              {errorMessageCreateListingDraft}
              {errorMessageUpdateListing}
              {errorMessageShowListing}
              <FieldTextInput
                id="title"
                name="title"
                className={css.title}
                type="textarea"
                label={titleMessage}
                placeholder={titlePlaceholderMessage}
                maxLength={TITLE_MAX_LENGTH}
                validate={composeValidators(required(titleRequiredMessage), maxLength80Message)}
                autoFocus
              />

              <h2 className={css.platesSectionTitle}>
                <FormattedMessage id="EditListingDescriptionForm.platesSectionTitle" />
              </h2>

              <PlateControl
                className={css.plateControl}
                plates={renderPlates}
                selectPlate={setActivePlate}
                activeKey={activePlate?.id}
              />

              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </Form>
            {plateForm}
          </>
        );
      }}
    />
  )
};

EditDiningExperienceListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditDiningExperienceListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditDiningExperienceListingDescriptionFormComponent);
